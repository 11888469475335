import logoImg from "./assets/logo/logo.png";

export { logoImg };

export const headerLinks = [
  // {
  //   name: "Jewellery",
  //   path: "/",
  // },
  {
    name: "Necklaces",
    path: "/shop/necklaces",
  },
  {
    name: "Earrings",
    path: "/shop/earrings",
  },
  {
    name: "Rings",
    path: "/shop/rings",
  },
  {
    name: "Bracelets",
    path: "/shop/Cuffs, Bracelets & Anklets",
  },
  {
    name: "About",
    path: "/about-us",
  },
];

export const smHeaderLinks = [
  {
    name: "Jewellery",
    path: "/",
  },
  {
    name: "Necklaces",
    path: "/shop/necklaces",
  },
  {
    name: "Earrings",
    path: "/shop/earrings",
  },
  {
    name: "Rings",
    path: "/shop/rings",
  },
  // {
  //   name: "Profile",
  //   path: "/login",
  // },
  {
    name: "My Cart",
    path: "/cart",
  },
  {
    name: "About",
    path: "/about-us",
  },
];

// must have
export const necklaceItems = [
  {
    id: 1,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/necklaces-1/1-1.jpg"),
    img2: require("./assets/images/necklaces-1/1-2.jpg"),
  },
  {
    id: 2,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/necklaces-1/2-1.jpg"),
    img2: require("./assets/images/necklaces-1/2-2.jpg"),
  },
  {
    id: 3,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/necklaces-1/3-1.jpg"),
    img2: require("./assets/images/necklaces-1/3-2.jpg"),
  },
  {
    id: 4,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/necklaces-1/4-1.jpg"),
    img2: require("./assets/images/necklaces-1/4-2.jpg"),
  },
  {
    id: 5,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/necklaces-1/5-1.jpg"),
    img2: require("./assets/images/necklaces-1/5-2.jpg"),
  },
  {
    id: 6,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/necklaces-1/6-1.jpg"),
    img2: require("./assets/images/necklaces-1/6-2.jpg"),
  },
  {
    id: 7,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/necklaces-1/7-1.jpg"),
    img2: require("./assets/images/necklaces-1/7-2.jpg"),
  },
  {
    id: 8,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/necklaces-1/8-1.jpg"),
    img2: require("./assets/images/necklaces-1/8-2.jpg"),
  },
  {
    id: 9,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/necklaces-1/9-1.jpg"),
    img2: require("./assets/images/necklaces-1/9-2.jpg"),
  },
  {
    id: 10,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/necklaces-1/10-1.jpg"),
    img2: require("./assets/images/necklaces-1/10-2.jpg"),
  },
  {
    id: 11,
    title: "Chunky Chain Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/necklaces-1/11-1.jpg"),
    img2: require("./assets/images/necklaces-1/11-2.jpg"),
  },
];

// earrings
export const earringsItems = [
  {
    id: 12,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/earrings-1/1-1.jpg"),
    img2: require("./assets/images/earrings-1/1-2.jpg"),
  },
  {
    id: 13,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/2-1.jpg"),
    img2: require("./assets/images/earrings-1/2-2.jpg"),
  },
  {
    id: 14,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/earrings-1/3-1.jpg"),
    img2: require("./assets/images/earrings-1/3-2.jpg"),
  },
  {
    id: 15,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/4-1.jpg"),
    img2: require("./assets/images/earrings-1/4-2.jpg"),
  },
  {
    id: 16,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/earrings-1/5-1.jpg"),
    img2: require("./assets/images/earrings-1/5-2.jpg"),
  },
  {
    id: 17,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/6-1.jpg"),
    img2: require("./assets/images/earrings-1/6-2.jpg"),
  },
  {
    id: 18,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/earrings-1/7-1.jpg"),
    img2: require("./assets/images/earrings-1/7-2.jpg"),
  },
  {
    id: 19,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/earrings-1/8-1.jpg"),
    img2: require("./assets/images/earrings-1/8-2.jpg"),
  },
  {
    id: 20,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/9-1.jpg"),
    img2: require("./assets/images/earrings-1/9-2.jpg"),
  },
  {
    id: 21,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/earrings-1/10-1.jpg"),
    img2: require("./assets/images/earrings-1/10-2.jpg"),
  },
  {
    id: 22,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/11-1.jpg"),
    img2: require("./assets/images/earrings-1/11-2.jpg"),
  },
  {
    id: 23,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/12-1.jpg"),
    img2: require("./assets/images/earrings-1/12-2.jpg"),
  },
  {
    id: 24,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/earrings-1/13-1.jpg"),
    img2: require("./assets/images/earrings-1/13-2.jpg"),
  },
  {
    id: 25,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/14-1.jpg"),
    img2: require("./assets/images/earrings-1/14-2.jpg"),
  },
  {
    id: 26,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/15-1.jpg"),
    img2: require("./assets/images/earrings-1/15-2.jpg"),
  },
  {
    id: 27,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/16-1.jpg"),
    img2: require("./assets/images/earrings-1/16-2.jpg"),
  },
  {
    id: 28,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/17-1.jpg"),
    img2: require("./assets/images/earrings-1/17-2.jpg"),
  },
  {
    id: 29,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/18-1.jpg"),
    img2: require("./assets/images/earrings-1/18-2.jpg"),
  },
  {
    id: 30,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/earrings-1/19-1.jpg"),
    img2: require("./assets/images/earrings-1/19-2.jpg"),
  },
  {
    id: 31,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/20-1.jpg"),
    img2: require("./assets/images/earrings-1/20-1.jpg"),
  },
  {
    id: 32,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/21-1.jpg"),
    img2: require("./assets/images/earrings-1/21-2.jpg"),
  },
  {
    id: 33,
    title: "Orbit Triple Hoops",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/earrings-1/22-1.jpg"),
    img2: require("./assets/images/earrings-1/22-2.jpg"),
  },
];

// rings
export const ringsItems = [
  {
    id: 34,
    title: "Stackable Pebble Rings",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/rings-1/1-1.jpg"),
    img2: require("./assets/images/rings-1/1-2.jpg"),
  },
  {
    id: 35,
    title: "Stackable Pebble Rings",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/rings-1/2-1.jpg"),
    img2: require("./assets/images/rings-1/2-2.jpg"),
  },
  {
    id: 36,
    title: "Stackable Pebble Rings",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/rings-1/3-1.jpg"),
    img2: require("./assets/images/rings-1/3-2.jpg"),
  },
  {
    id: 37,
    title: "Stackable Pebble Rings",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/rings-1/4-1.jpg"),
    img2: require("./assets/images/rings-1/4-2.jpg"),
  },
  {
    id: 38,
    title: "Stackable Pebble Rings",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/rings-1/5-1.jpg"),
    img2: require("./assets/images/rings-1/5-2.jpg"),
  },
  {
    id: 39,
    title: "Stackable Pebble Rings",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/rings-1/6-1.jpg"),
    img2: require("./assets/images/rings-1/6-2.jpg"),
  },
  {
    id: 40,
    title: "Stackable Pebble Rings",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/rings-1/7-1.jpg"),
    img2: require("./assets/images/rings-1/7-2.jpg"),
  },
  {
    id: 41,
    title: "Stackable Pebble Rings",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/rings-1/8-1.jpg"),
    img2: require("./assets/images/rings-1/8-2.jpg"),
  },
  {
    id: 42,
    title: "Stackable Pebble Rings",
    price: 2500,
    isBestSeller: true,
    img: require("./assets/images/rings-1/9-1.jpg"),
    img2: require("./assets/images/rings-1/9-2.jpg"),
  },
  {
    id: 43,
    title: "Stackable Pebble Rings",
    price: 2500,
    isBestSeller: false,
    img: require("./assets/images/rings-1/10-1.jpg"),
    img2: require("./assets/images/rings-1/10-2.jpg"),
  },
];

// celebrity jewellery
export const celebrityJewelleryItems = [
  {
    id: 1,
    img: require("./assets/images/celebrities/1-1.png"),
    img2: require("./assets/images/celebrities/1-2.png"),
  },
  {
    id: 2,
    img: require("./assets/images/celebrities/2-1.png"),
    img2: require("./assets/images/celebrities/2-2.png"),
  },
  {
    id: 3,
    img: require("./assets/images/celebrities/3-1.png"),
    img2: require("./assets/images/celebrities/3-2.png"),
  },
  {
    id: 4,
    img: require("./assets/images/celebrities/4-1.png"),
    img2: require("./assets/images/celebrities/4-2.png"),
  },
];
